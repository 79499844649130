import { ActionType, ApprovalStatus } from '../config/CustomEnums';
import { getInFilterValue, getSearchKey } from '../utils';
import { BaseHelper } from './BaseGraphQLAPIHelper';

export const SimpleListWithCouponNode = `
node {
  pk
  id
  type
  name
  earningCampaignTypeEarningRule {
    id
    pk
    type
    name
    birthdayTypePeriodStartDate
    birthdayTypePeriodEndDate
    generalPurchaseTypeCouponRewardTypeCouponTemplate {
      id
      pk
      name
    }
    memberReferralTypeCouponRewardTypeCouponTemplate {
      id
      pk
      name
    }
    newMemberTypeCouponRewardTypeCouponTemplate {
      id
      pk
      name
    }
    birthdayTypeCouponRewardTypeCouponTemplate {
      id
      pk
      name
    }
    qrCodeScanningTypeCouponRewardTypeCouponTemplate {
      id
      pk
      name
    }
    gpsCheckInTypeCouponRewardTypeCouponTemplate {
      id
      pk
      name
    }
  }
}
`

export const SimpleListForEstampWithCouponNode = `
node {
  pk
  id
  type
  name
  couponCampaignTypeCouponTemplate {
    id
    pk
    name
  }
}
`
const SimpleListFieldNode = `
node {
  pk
  id
  type
  name
}
`

const ListFieldNode = `
node {
  pk
  id
  displayId
  type
  name
  displayIn
  startDate
  endDate
  displayStartDate
  displayEndDate
  approvalStatus
  lastModifiedDate
  publicationDate
  displayPriority
  status
  coverPhoto
  numberOfLikes
  numberOfBookmarks
  brand(orderBy:"pk") {
    edges {
      node {
        id
        pk
        name
      }
    }
  }
  numberOfCustomersVisible
  isExclusive
  messageChannels
  missionCampaignRelation(isPublished: true) {
    edges {
      node {
        id
        pk
        displayOrder
        missionCampaign {
          pk
          id
          type
          displayIn
          name
        }
      }
    }
  }
  translations {
    edges {
      node {
        language
        name
      }
    }
  }
}
`

export const getTransactionNode = (transactionFilter) => {
  return `
  node {
    pk
    id
    displayId
    type
    name
    campaignTransactionError (${transactionFilter})
    earningCampaignTypeEarningRule {
      generalPurchaseTypeEligibleNumberOfDaysSincePurchase
      generalPurchaseTypePerHeadLimit
      generalPurchaseTypePeriodicLimit
      generalPurchaseTypePeriodicLimitEffectiveNumberOfDays
      generalPurchaseTypePerHeadPeriodicLimitEffectiveNumberOfDays
      generalPurchaseTypePerHeadPeriodicLimit
    }
    missionCampaignRelation(isPublished: true) {
      edges {
        node {
          id
          pk
          displayOrder
          missionCampaign {
            pk
            id
            type
            displayIn
            name
            missionCampaignMode
          }
        }
      }
    }
    translations {
      edges {
        node {
          language
          name
        }
      }
    }
  }
  `
}

export const getCampaignList = (
  afterCursor,
  filter
  // type,
  // reverse,
  // filterName,
  // ssoUid,
  // isAvailable,
  // others,
) => {
  console.log("@68", filter)
  let {type, reverse, searchKey, ssoUid, isAvailable, others, isSimpleList, fieldNode, isPublished} = filter;
  if (!searchKey) {
    searchKey = ''
  }
  if (!type) {
    type = ''
  }
  let userFilter = '';
  if (ssoUid) {
    userFilter = `, isAvailable: true, customerSsoUid: "${ssoUid}"`;
  }

  if (!ssoUid && isAvailable && others?.usedPosition != 'export' ) {
    userFilter = `, isAvailable: true`;
  }

  if (isPublished) {
    userFilter += `, isPublished: true`;
  }

  // if (others.moreSearch) {
  //   userFilter += ', ' + others.moreSearch;
  // }

  if (others?.status) {
    userFilter += `, approvalStatusIn: ${getInFilterValue(others.status)}`;
  }
  if (others?.expired) {
    userFilter += `, isExpired: ${others.expired}`;
  }
  if (others?.message_channel) {
    userFilter += `, messageChannelsIn: ${getInFilterValue(others.message_channel)}`;
  }
  if (others?.brand) {
    userFilter += `, brandIn: ${getInFilterValue(others.brand)}`;
  }
  if (others?.active_period) {
    const activePeriod = others.active_period.split(',');
    userFilter += `, startDateGte: "${activePeriod[0]}", endDateLte: "${activePeriod[1]}"`;
  }
  if (others?.visable_period) {
    const visablePeriod = others.visable_period.split(',');
    userFilter += `, displayStartDateGte: "${visablePeriod[0]}", displayEndDateLte: "${visablePeriod[1]}"`;
  }
  if (others?.target_customer) {
    if (others[others.target_customer])
      userFilter += `, ${others.target_customer}: ${
        getInFilterValue(others[others.target_customer])
      }`;
  }
  let orderBy = reverse ? '-displayId' : 'displayId';
  if (others?.is_featured) {
    orderBy = 'displayPriority';
    userFilter += `, isFeatured: true`;
  }
  if (others?.isExclusive) {
    userFilter += `, isExclusive: ${others.isExclusive}`;
  }
  if (others?.filterTemplateCampaign) {
    userFilter += `, templateCampaignFilter: "${others.filterTemplateCampaign}"`;
  }
  if (others?.sort) {
    orderBy = others.sort;
  }

  if (others?.all || others?.isAll || filter?.isAll) {
    userFilter += ``;
  } else {
    userFilter += `, first: ${filter?.pageSize ?  filter?.pageSize : 20}`;
  }

  if (others?.displayIn) {
    userFilter += `, displayIn: ${getInFilterValue(others.displayIn)}`
  } else if (others?.display_in) {
    userFilter += `, displayIn: ${getInFilterValue(others.display_in)}`
  } else if (others?.allDisplayList) {
    const displayIn = "E_COUPON,STAMP_LIST,MISSION_LIST,INTEREST_CLUB,FRESH_MARKET_COMMUNITY,NOT_LISTING"
    userFilter += `, displayIn: ${getInFilterValue(displayIn)}`
  } else if (!others?.allDisplayIn) {
    const displayIn = "E_COUPON,STAMP_DETAIL,MISSION_DETAIL,INTEREST_CLUB,FRESH_MARKET_COMMUNITY,NOT_LISTING"
    userFilter += `, displayIn: ${getInFilterValue(displayIn)}`
  }
  if (others?.tasksForMissionMode) {
    userFilter += `, tasksForMissionMode: "${others.tasksForMissionMode}"`
  }

  if (others?.transactionFilter) {
    userFilter += `, transactionFilter: ${getInFilterValue(others.transactionFilter)}`
  }
  if (others?.mallTransactionFilter) {
    userFilter += `, mallTransactionFilter: ${getInFilterValue(others.mallTransactionFilter)}`
  }
  if (others?.transactionCampaigns) {
    userFilter += `, transactionCampaigns: ${getInFilterValue(others.transactionCampaigns)}`
  }
  if (others?.missionCampaignId) {
    userFilter += `, missionCampaignId: "${others.missionCampaignId}"`
  }
  if (others?.stampCampaignId) {
    userFilter += `, stampCampaignId: "${others.stampCampaignId}"`
  }
  console.log('@@46: ', others);
  console.log('@@userFilter: ', userFilter);
  const node = fieldNode || others?.fieldNode ? fieldNode || others?.fieldNode : isSimpleList ? SimpleListFieldNode : ListFieldNode;
  const query = `{
      campaigns(after:"${afterCursor}", orderBy: "${orderBy}", nameOrId:"${getSearchKey(searchKey)}", type: "${
    type === 'AllTypes' ? '' : type
  }"${userFilter}) {
        totalCount
        maxDisplayPriority
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          ${node}
        }
      }
    }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getCampaign = (id) => {
  const query = `{
    campaign(id: "${id}") {
      id
      pk
      type
      name
      publisherType
      providerName
      providerPhoto
      missionCampaignHasStarted
      isTempFortuneBag
      creationDate
      lastModifiedDate
      htmlContent
      shortDescription
      instructionSectionTitle
      instructionSectionContent
      detailSectionTitle
      detailSectionContent
      startDate
      endDate
      numberOfLikes
      numberOfBookmarks
      blackoutPeriod {
        edges{
          node {
            pk
            startDate
            endDate
          }
        }
      }
      blackoutWeekday
      displayStartDate
      displayEndDate
      isFeatured
      isExclusive
      displayIn
      applyCoverPhotoToAllLanguage
      applyDetailPhotosToAllLanguage
      # applySquareCoverPhotoToAllLanguage
      approvalStatus
      publicationDate
      coverPhoto
      coverPhotoLandingUrl
      # squareCoverPhoto
      detailPhoto1
      detailPhoto2
      detailPhoto3
      detailPhoto4
      detailPhotoLandingUrl1
      detailPhotoLandingUrl2
      detailPhotoLandingUrl3
      detailPhotoLandingUrl4
      missionCampaignTncUrl
      missionCampaignPrivacyPolicyUrl
      language
      displayPriority
      enablePromotion
      promotionTnc
      promotionType
      promotionContact
      promotionPoster
      promotionMagazineLink
      lastYearPromotionId
      deliveryChannel
      categories {
        edges {
          node{
            pk
            name
            id
            type
          }
        }
      }
      targetedCustomerGroups {
        edges {
          node {
            pk
            name
            id
          }
        }
      }
      targetedSegments {
        edges {
          node{
            pk
            name
            id
          }
        }
      }
      translations {
        edges {
          node {
            id
            pk
            name
            shortDescription
            providerName
            providerPhoto
            instructionSectionTitle
            instructionSectionContent
            detailSectionTitle
            detailSectionContent
            coverPhoto
            coverPhotoLandingUrl
            # squareCoverPhoto
            detailPhoto1
            detailPhoto2
            detailPhoto3
            detailPhoto4
            detailPhotoLandingUrl1
            detailPhotoLandingUrl2
            detailPhotoLandingUrl3
            detailPhotoLandingUrl4
            language
            htmlContent
            missionCampaignTncUrl
            missionCampaignPrivacyPolicyUrl
            promotionPoster
            promotionMagazineLink
            missionCampaignRedeemedGiftImage
            missionCampaignRedeemedFinalGiftImage
          }
        }
      }
      couponCampaignTypeCouponTemplate {
          id
          pk
          name
          absoluteExpiryDate
          totalNubmerOfGeneratedCoupons
      }
      couponCampaignTypeShouldShowCouponStock
      couponCampaignTypeOverallLimit
      couponCampaignTypePerHeadLimit
      couponCampaignTypeRequiredStamps
      fortuneBagCampaignTypeCouponTemplates {
        edges {
          node {
            id
            pk
            name
            absoluteExpiryDate
            totalNubmerOfGeneratedCoupons
            stock
          }
        }
      }
      fortuneBagCampaignTypeOverallLimit
      fortuneBagCampaignTypePeriodicLimit
      fortuneBagCampaignTypePeriodicLimitEffectiveNumberOfDays
      fortuneBagCampaignTypePerHeadLimit
      fortuneBagCampaignTypePerHeadPeriodicLimit
      fortuneBagCampaignTypePerHeadPeriodicLimitEffectiveNumberOfDays
      fortuneBagCampaignTypeAcquireCouponNumberEachTime
      earningCampaignTypeEarningRule {
          id
          pk
          name
          type
          birthdayTypePeriodStartDate
          birthdayTypePeriodEndDate
          generalPurchaseTypeCouponRewardTypeCouponTemplate {
            id
            pk
            name
            absoluteExpiryDate
          }
          memberReferralTypeCouponRewardTypeCouponTemplate {
            id
            pk
            name
            absoluteExpiryDate
          }
          newMemberTypeCouponRewardTypeCouponTemplate {
            id
            pk
            name
            absoluteExpiryDate
          }
          birthdayTypeCouponRewardTypeCouponTemplate {
            id
            pk
            name
            absoluteExpiryDate
          }
          qrCodeScanningTypeCouponRewardTypeCouponTemplate {
            id
            pk
            name
            absoluteExpiryDate
          }
          gpsCheckInTypeCouponRewardTypeCouponTemplate {
            id
            pk
            name
            absoluteExpiryDate
          }
          fillingFormTypeCouponRewardTypeCouponTemplate {
            id
            pk
            name
            absoluteExpiryDate
          }
      }
      brand(orderBy:"pk") {
        edges {
          node {
            id
            pk
            name
          }
        }
      }
      stores {
        edges {
          node {
            pk
            name
            propertyType
            staffCode
            mall {
              pk
              name
            }
            freshMarket {
              pk
              name
            }
          }
        }
      }
      relatedStores {
        edges {
          node {
            pk
            name
            propertyType
            staffCode
            mall {
              pk
              name
            }
            freshMarket {
              pk
              name
            }
          }
        }
      }
      tags {
        edges {
          node {
            pk
            name
          }
        }
      }
      isShowInMall
      malls {
        edges {
          node {
            pk
            name
          }
        }
      }
      relatedCampaigns {
        edges {
          node {
            pk
            name
          }
        }
      }
      stampCampaignTypeLinkCouponCampaigns {
        edges {
          node {
            id
            pk
            name
            couponCampaignTypeCouponTemplate {
              id
              pk
              name
            }
          }
        }
      }
      stampCampaignTypePerHeadOwnedStampLimit
      stampCampaignTypeEmptySlotIcon
      stampCampaignTypeAcquiredStampIcon
      missionCampaignMode
      missionCampaignParticipant
      missionCampaignMinimumNumberOfInvitees
      missionCampaignMaximumNumberOfInvitees
      missionCampaignEmptySlotBadgeImage
      missionCampaignAcquiredBadgeImage
      missionCampaignEmptyGiftSlotImage
      missionCampaignCollectedGiftImage
      missionCampaignRedeemedGiftImage
      missionCampaignEmptyFinalGiftSlotImage
      missionCampaignCollectedFinalGiftImage
      missionCampaignRedeemedFinalGiftImage
      missionCampaignTotalBadgeQuantity
      earningCampaignRelation{
        edges{
          node{
            earningCampaign{
              id
              pk
              name
              earningCampaignTypeEarningRule {
                id
                pk
                name
                type
                generalPurchaseTypeCouponRewardTypeCouponTemplate {
                  id
                  pk
                  name
                }
                memberReferralTypeCouponRewardTypeCouponTemplate {
                  id
                  pk
                  name
                }
                newMemberTypeCouponRewardTypeCouponTemplate {
                  id
                  pk
                  name
                }
                birthdayTypeCouponRewardTypeCouponTemplate {
                  id
                  pk
                  name
                }
                qrCodeScanningTypeCouponRewardTypeCouponTemplate {
                  id
                  pk
                  name
                }
                gpsCheckInTypeCouponRewardTypeCouponTemplate {
                  id
                  pk
                  name
                }
              }
            }
            displayOrder
            dynamicRewardReceiver
          }
        }
      }
      linkedRewards{
        edges{
          node{
            pk
            couponTemplate{
              name
              id
              pk
            }
            requiredBadges
            couponQuantity
            recipient
          }
        }
      }
    }
  }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getCampaignCategoryList = ({ search }) => {
  let searchString = '';
  // console.log('@@225: ', search);
  // if (search.isActive) {
  //   searchString += `, is_active: ${search.isActive}`;
  // }
  const query = `{
    campaignCategories(first: 20 ${searchString}) {
      edges {
        cursor
        node {
          id
          pk
          name
          type
        }
      }
    }
  }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const createCampaignCategory = (input) => {
  const query = `mutation CreateCampaignCategory($input: CreateCampaignCategoryInput!) {
    createCampaignCategory(input: $input) {
      node {
        id
        pk
        name
        displayPriority
        translations {
          edges {
            node {
              language
              name
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const createCampaign = (campaign) => {
  const query = `mutation CreateCampaign($input: CreateCampaignInput!) {
    createCampaign(input: $input) {
      node {
        id
        pk
        type
        displayIn
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: {
      ...campaign,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const CreateTempCampaign = (campaign) => {
  const query = `mutation CreateTempCampaign($input: CreateTempCampaignInput!) {
    createTempCampaign(input: $input) {
      node {
        id
        pk
        type
        displayIn
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: {
      ...campaign,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const publishCampaign = (input) => {
  const query = `mutation PublishCampaign($input: PublishCampaignInput!) {
    publishCampaign(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const unPublishCampaign = (input) => {
  const query = `mutation UnpublishCampaign($input: UnpublishCampaignInput!) {
    unpublishCampaign(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateApprovalStatus = (input, approvalStatus, actionType) => {
  let mutation = null
  switch (actionType) {
    case ActionType.publish:
      mutation = 'PublishCampaign'
      break
    case ActionType.unpublish:
      mutation = 'UnpublishCampaign'
      break
    case ActionType.approval:
      mutation = 'ApproveCampaign'
      break
    case ActionType.submitForApproval:
      mutation = 'PendingForApprovalCampaign'
      break
    case ActionType.withdraw:
      if (approvalStatus === ApprovalStatus.pendingForApproval){
        mutation = 'WithdrawPendingForApprovalCampaign'
      } else {
        mutation = 'WithdrawApprovedCampaign'
      }
      break
    default:
      mutation = null
      break
  }
  console.log("@663 mutation", mutation)
  if (!mutation) {
    return
  }

  const query = `mutation ${mutation}($input: ${mutation}Input!) {
    ${mutation.charAt(0).toLowerCase()}${mutation.slice(1)}(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
}

export const deleteCampaign = (ids) => {
  const query = `mutation DeleteCampaigns($input: DeleteCampaignsInput!) {
    deleteCampaigns(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

// export const deleteCampaignTranslation = (ids) => {
//   const query = `mutation DeleteCampaignTranslation($input: DeleteCampaignTranslationInput!) {
//     deleteCampaignTranslation(input: $input) {
//       success
//     }
//   }
//   `;
//   const variables = ids.map((item) => {
//     return {
//       query: query,
//       variables: {
//         input: {
//           id: item,
//         },
//       },
//     };
//   });
//   return BaseHelper.callGraphQLAPIMultiple(variables);
// };

// export const deleteCampaignBrand = (ids) => {
//   const query = `mutation DeleteCampaignBrandSection($input: DeleteCampaignBrandSectionInput!) {
//     deleteCampaignBrandSection(input: $input) {
//       success
//     }
//   }
//   `;
//   const variables = ids.map((item) => {
//     return {
//       query: query,
//       variables: {
//         input: {
//           id: item,
//         },
//       },
//     };
//   });
//   return BaseHelper.callGraphQLAPIMultiple(variables);
// };

export const duplicateCampaign = (id) => {
  const query = `mutation duplicateCampaign($input: DuplicateCampaignInput!) {
    duplicateCampaign(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      id,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateCampaign = (input) => {
  const query = `mutation UpdateCampaign($input: UpdateCampaignInput!) {
    updateCampaign(input: $input) {
      success
      node {
        id
        pk
        name
        type
        displayIn
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneEarningRule = (id) => {
  const query = `
  {
    earningRule(id: "${id}") {
      id
      pk
      name
      type
      birthdayTypePeriodStartDate
      birthdayTypePeriodEndDate
      generalPurchaseTypeCouponRewardTypeCouponTemplate {
        id
        pk
        name
      }
      memberReferralTypeCouponRewardTypeCouponTemplate {
        id
        pk
        name
      }
      newMemberTypeCouponRewardTypeCouponTemplate {
        id
        pk
        name
      }
      birthdayTypeCouponRewardTypeCouponTemplate {
        id
        pk
        name
      }
      qrCodeScanningTypeCouponRewardTypeCouponTemplate {
        id
        pk
        name
      }
      gpsCheckInTypeCouponRewardTypeCouponTemplate {
        id
        pk
        name
      }
      fillingFormTypeCouponRewardTypeCouponTemplate {
        id
        pk
        name
      }
    }
  }
  `;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneCoupon = (id) => {
  const query = `
  {
    couponTemplate(id: "${id}") {
      id
      pk
      name
      totalNubmerOfGeneratedCoupons
      shortDescription
      applyCoverPhotoToAllLanguage
      applyDetailPhotosToAllLanguage
      instructionSectionTitle
      instructionSectionContent
      detailSectionTitle
      detailSectionContent
      coverPhoto
      detailPhoto1
      detailPhoto2
      detailPhoto3
      detailPhoto4
      absoluteExpiryDate
      brand {
        edges {
          node {
            id
            pk
            name
          }
        }
      }
      translations {
        edges {
          node {
            language
            coverPhoto
            detailPhoto1
            detailPhoto2
            detailPhoto3
            detailPhoto4
            instructionSectionTitle
            instructionSectionContent
            detailSectionTitle
            detailSectionContent
            name
            shortDescription
          }
        }
      }
    }
  }
  `;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const rejectPendingForPublishCampaign = (input) => {
  const query = `
  mutation RejectPendingForPublishCampaign($input: RejectPendingForPublishCampaignInput!) {
    rejectPendingForPublishCampaign(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const rejectPendingForApprovalCampaign = (input) => {
  const query = `
  mutation RejectPendingForApprovalCampaign($input: RejectPendingForApprovalCampaignInput!) {
    rejectPendingForApprovalCampaign(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
