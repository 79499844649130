import {
  createEarningRule,
  updateEarningRule,
  getOneEarningRule,
  createOrUpdateEarningRule,
} from '../services/EarningRuleHelper';
import {
  EarningRuleType,
  EarningRuleTypeKey,
  LanguageConfig,
  CheckStatus,
  EarningRuleRewardType,
  SESSION_KEYS,
  APIStatus,
  BonusRewardsRule,
  BonusCriteriaRelationship,
} from '../config/CustomEnums';
import { defaultStep, getNewStepConfig } from './StepBarUtil';
import { apiWithResponseHandle, loading } from './LoadingUtil';
import { convertCursorToNumber, convertNumberToCursor } from '../utils';
import {
  getFileNameFromUrl,
  saveToSessionStorage,
  removeFromSessionStorage,
  getObjectFromSessionStorage,
  createAction,
  delay,
  addDomainToImage,
  convertPKToId,
  getKeyByValue,
} from '../utils';
import { EarningRuleErrorHandleField } from '../components/earning/EarningRuleHandleError';
import { SaveAndBackWithOutTempButtons } from '../components/base/BottomStepComponent';

export const EARNING_RULES_SESSION_KEY =
  SESSION_KEYS.EARNING_RULES_SESSION_KEY;

const stepNames = ['Type', 'Content', 'Properties'];

const getInitialState = () => ({
  currentStep: 0,
  stepConfig: defaultStep(stepNames),
  earningRule: {},
  errorFields: [],
  formChanged: false,
  checked: CheckStatus.initOrNotChecked,
  hasUpdatedDefaultValues: false,
  createStatus: APIStatus.none,
});

const postTranslations = (data) => {
  if (!data) {
    return [];
  }
  const languageList = Object.keys(data).filter(
    (language) => language !== LanguageConfig.english,
  );
  const translations = languageList.map((language) => {
    const newData = {
      language,
      instructionSectionTitle: data?.[language]?.instructionSectionTitle,
      instructionSectionContent: data?.[language]?.instructionSectionContent,
      detailSectionTitle: data?.[language]?.detailSectionTitle,
      detailSectionContent: data?.[language]?.detailSectionContent,
    };
    const id = data?.[language]?.pk;
    if (id) {
      newData.id = id;
    }
    return newData;
  });
  return translations;
};

const getTranslations = (data) => {
  const translationList = data.translations?.edges || [];
  const translation = {};
  translationList.forEach((item) => {
    const language = item.node.language;
    translation[language] = {
      ...item.node,
      // name: item.node.name,
    };
  });
  translation[LanguageConfig.english] = {
    instructionSectionTitle: data.instructionSectionTitle,
    instructionSectionContent: data.instructionSectionContent,
    detailSectionTitle: data.detailSectionTitle,
    detailSectionContent: data.detailSectionContent,
    pk: data.pk,
  };
  return translation;
};

const getQuantity = (defaultData, memberType) => {
  const type = EarningRuleTypeKey[defaultData.type]
  switch (defaultData[`${type}TypeRewardType`]) {
    case EarningRuleRewardType.points:
      return defaultData[`${type}TypePointsRewardTypePoints${memberType}`]
    case EarningRuleRewardType.badge:
      return defaultData[`${type}TypeBadgeRewardTypeQuantity${memberType}`]
    case EarningRuleRewardType.stamp:
      return defaultData[`${type}TypeStampRewardTypeQuantity${memberType}`]
    default:
      return defaultData[`${type}TypeCouponRewardTypeQuantity${memberType}`]
  }
}

const getPurchaseTypeReward = (defaultData) => {
  switch (defaultData.generalPurchaseTypeRewardType) {
    case EarningRuleRewardType.points:
      return {
        quantity: defaultData.generalPurchaseTypePointsRewardTypePointsPerXDollarsSpent,
        rewardTypeX: defaultData.generalPurchaseTypePointsRewardTypeX
      }
    case EarningRuleRewardType.badge:
      return {
        quantity: defaultData.generalPurchaseTypeBadgeRewardTypeBadgesPerXDollarsSpent,
        rewardTypeX: defaultData.generalPurchaseTypeBadgeRewardTypeX
      }
    case EarningRuleRewardType.stamp:
      const bonusSelectedStores = defaultData.generalPurchaseTypeStampRewardTypeBonusStores?.edges?.map((item) => item.node) || [];
      return {
        quantity: defaultData.generalPurchaseTypeStampRewardTypeStampsPerXDollarsSpent,
        rewardTypeX: defaultData.generalPurchaseTypeStampRewardTypeX,
        stampRewardTypeBonus: defaultData.generalPurchaseTypeStampRewardTypeBonus,
        bonusRewardsRule: defaultData.generalPurchaseTypeStampRewardTypeBonusType,
        multiplier: defaultData.generalPurchaseTypeStampRewardTypeBonusMultiplier,
        additional: defaultData.generalPurchaseTypeStampRewardTypeBonusAdditional,
        bonusCriteriaRelationship: defaultData.generalPurchaseTypeStampRewardTypeBonusCriteriaRelationship,
        bonusPaymentMetheds: defaultData.generalPurchaseTypeStampRewardTypeBonusPaymentMethods?.edges?.map((item) => item.node),
        bonusSelectMallStores: bonusSelectedStores.filter((store) => store.propertyType === "MALL_STORE"),
        bonusSelectFreshMarketStores: bonusSelectedStores.filter((store) => store.propertyType === "FRESH_MARKET_STORE"),
        bonusStartDate: defaultData.generalPurchaseTypeStampRewardTypeBonusTransactionStartDatetime,
        bonusEndDate: defaultData.generalPurchaseTypeStampRewardTypeBonusTransactionEndDatetime,
        customerGroup: defaultData.generalPurchaseTypeStampRewardTypeBonusTargetCustomerGroups?.edges?.map((item) => item.node),
        segments: defaultData.generalPurchaseTypeStampRewardTypeBonusTargetCustomerSegments?.edges?.map((item) => item.node),
      }
    default:
      return {
        quantity: defaultData.generalPurchaseTypeCouponRewardTypeQuantity
      }
  }
}

const praseEarningRule = (defaultData) => {
  if (!defaultData) {
    return {}
  }
  let formatData = defaultData;
  let commonData = {};

  if (Object.keys(defaultData).length > 0) {
    const type = EarningRuleTypeKey[defaultData.type];
    const selectedStores = defaultData.generalPurchaseTypeLimitedToStores?.edges?.map((item) => item.node)
    formatData = {
      id: defaultData.id,
      pk: defaultData.pk,
      type: defaultData.type,
      generalName: defaultData.name,
      minSpending: defaultData.generalPurchaseTypeMinimumSpending,
      maxSpending: defaultData.generalPurchaseTypeMaximumSpending,
      eligibleDays:
        defaultData.generalPurchaseTypeEligibleNumberOfDaysSincePurchase,
      paymentMetheds: defaultData.generalPurchaseTypeLimitedToPaymentMethods?.edges?.map((item) => item.node),
      selectMallStores: selectedStores.filter((store) => store.propertyType === "MALL_STORE"),
      selectFreshMarketStores: selectedStores.filter((store) => store.propertyType === "FRESH_MARKET_STORE"),
      // isExcludeDeliveryCost:
      //   defaultData.generalPurchaseTypeWillExcludeDeliveryCost,
      // isExcludeOtherCharges:
      //   defaultData.generalPurchaseTypeWillExcludeOtherCharges,
      // specialSkus: defaultData.generalPurchaseTypeLimitedToSkus?.edges.map(
      //   (item) => ({
      //     pk: item.node.skuId,
      //     name: item.node.sku,
      //     category: {
      //       pk: item.node.categoryId,
      //       name: item.node.categoryName,
      //     },
      //   }),
      // ),

      referralType: defaultData.memberReferralTypeBeneficiary,
      maxRewards: defaultData.memberReferralTypeLimit,

      latitude: defaultData.gpsCheckInTypeLatitude,
      longitude: defaultData.gpsCheckInTypeLongitude,
      radius: defaultData.gpsCheckInTypeRadiusInMeter,

      birthStart: defaultData.birthdayTypePeriodStartDate,
      birthEnd: defaultData.birthdayTypePeriodEndDate,

      qrCodes: defaultData.qrCodeScanningTypeQrCodes,

      newMemberTypePromotionCodes: defaultData.newMemberTypePromotionCodes,

      fillingForm: defaultData.fillingFormTypeForm,
      definitionType: defaultData.fillingFormTypeDefinitionType,

      rewardType: defaultData[`${type}TypeRewardType`],
      quantity: getQuantity(defaultData, ''),
      inviterQuantity: getQuantity(defaultData, 'Inviter'),
      inviteeQuantity: getQuantity(defaultData, 'Invitee'),
      coupons: defaultData[`${type}TypeCouponRewardTypeCouponTemplate`],
      translations: getTranslations(defaultData),
    };

    switch (defaultData.type) {
      case EarningRuleType.generalPurchase:
        commonData = {
          ...getPurchaseTypeReward(defaultData),
          overallLimit: defaultData[`${type}TypeOverallLimit`],
          perHeadLimit: defaultData[`${type}TypePerHeadLimit`],
          periodicLimit: defaultData[`${type}TypePeriodicLimit`],
          periodicLimitDays:
            defaultData[`${type}TypePeriodicLimitEffectiveNumberOfDays`],
          perHeadPeriodicLimit:
            defaultData[`${type}TypePerHeadPeriodicLimit`],
          perHeadPeriodicLimitDays:
            defaultData[
              `${type}TypePerHeadPeriodicLimitEffectiveNumberOfDays`
            ],
        };
        break;
      case EarningRuleType.gpsCheckIn:
      case EarningRuleType.qrCodeScanning:
      case EarningRuleType.fillingForm:
        commonData = {
          overallLimit: defaultData[`${type}TypeOverallLimit`],
          perHeadLimit: defaultData[`${type}TypePerHeadLimit`],
          periodicLimit: defaultData[`${type}TypePeriodicLimit`],
          periodicLimitDays:
            defaultData[`${type}TypePeriodicLimitEffectiveNumberOfDays`],
          perHeadPeriodicLimit:
            defaultData[`${type}TypePerHeadPeriodicLimit`],
          perHeadPeriodicLimitDays:
            defaultData[
              `${type}TypePerHeadPeriodicLimitEffectiveNumberOfDays`
            ],
        };

        break;

      case EarningRuleType.memberReferral:
      case EarningRuleType.birthday:
        commonData = {};
        break;

      default:
        break;
    }
  }

  const earningRule = { ...formatData, ...commonData };
  return earningRule
}

export default {
  namespace: 'createEarningRules',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    stepChange(state, { payload }) {
      const isBack = payload.isBack;
      let step = payload.step;
      const isValid = payload.isValid;
      const stepConfig = getNewStepConfig(
        step,
        state.stepConfig,
        !isValid,
        isBack,
      );
      if (isValid) {
        step = isBack ? step - 1 : step + 1;
      }
      console.log(
        '@@106: ',
        step,
        '->',
        isValid,
        payload
      );
      return {
        ...state,
        currentStep: step,
        stepConfig,
        createStatus: APIStatus.none,
      }
    },

    loadEarningRuleFromCookie(state, { payload }) {
      const earningRule = getObjectFromSessionStorage(EARNING_RULES_SESSION_KEY) || {};
      // const defaultData = payload ? payload.data.earningRule : {};

      // let earningRule = defaultData;
      // if (!tempEarningRule) {
      //   earningRule = praseEarningRule(defaultData)
      // }

      // if (!defaultData.pk) {
      //   earningRule = tempEarningRule || {};
      //   delete earningRule.id;
      //   delete earningRule.pk;
      // }
      console.log("@259 earningRule", earningRule)
      // saveToSessionStorage(EARNING_RULES_SESSION_KEY, earningRule);
      return {
        ...state,
        earningRule: {
          ...earningRule,
          ...payload.createType,
        },
        hasUpdatedDefaultValues: true,
      };
    },
    removeEarningRuleFromCookie(state, { payload }) {
      removeFromSessionStorage(EARNING_RULES_SESSION_KEY);

      return {
        ...state,
        hasUpdatedDefaultValues: false,
      };
    },
    // clearData(state, { payload }) {
    //   return { ...state, ...getInitState() };
    // },

    // saveOrRemoveEarningRuleFromCookie(state, { payload }) {
    //   let campaign = getObjectFromSessionStorage(EARNING_RULES_SESSION_KEY);
    //   if (payload.data) {
    //     campaign = { ...payload.data };
    //     saveToSessionStorage(EARNING_RULES_SESSION_KEY, campaign);
    //   } else {
    //     removeFromSessionStorage(EARNING_RULES_SESSION_KEY);
    //   }
    //   return {
    //     ...state,
    //   };
    // },
    changeVals(state, { payload }) {
      console.log('@@138: vals changed', payload);
      let tempEarningRule = getObjectFromSessionStorage(EARNING_RULES_SESSION_KEY);
      if (payload.vals) {
        let data = {};
        if (payload.language) {
          data[payload.language] = {
            ...tempEarningRule[payload.language],
            ...payload.vals,
          };
        } else {
          data = payload.vals;
        }

        tempEarningRule = { ...tempEarningRule, ...data };
        saveToSessionStorage(EARNING_RULES_SESSION_KEY, tempEarningRule);
      }

      return {
        ...state,
        formChanged: true,
      };
    },
    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },

  effects: {
    getOneEarningRule: [
      function* ({ payload }, { call, select, put }) {
        yield put({
          type: 'updateState',
          payload: { createStatus: APIStatus.calling },
        });
        const serviceArgs = [
          getOneEarningRule,
          convertPKToId('EarningRuleNode', payload.id),
        ];
        function* onSuccess(data) {
          console.log(data)
          const earningRule = praseEarningRule(data?.earningRule)
          //save to state.earningRule
          // removeFromSessionStorage(EARNING_RULES_SESSION_KEY);
          // yield put({
          //   type: 'loadEarningRuleFromCookie',
          //   payload: { data },
          //   hasUpdatedDefaultValues: true,
          // });
          yield put({
            type: 'updateState',
            payload: {
              earningRule: earningRule,
              createStatus: APIStatus.none,
              hasUpdatedDefaultValues: true,
            },
          });
          const action = payload?.afterAction || (() => {});
          yield action(earningRule);
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    createOrUpdateEarningRule: [
      function* ({ payload }, { call, all, put, select }) {
        // const tempEarningRule = getObjectFromSessionStorage(EARNING_RULES_SESSION_KEY);
        const tempEarningRule = payload.data
        console.log("@327", tempEarningRule, payload)
        if (!tempEarningRule?.type) {
          return;
        }
        const oldEarningRule = yield select(
          (state) => state.createEarningRules.earningRule,
        );

        const key = EarningRuleTypeKey[tempEarningRule.type];

        let data = {
          name: tempEarningRule.generalName,
          type: tempEarningRule.type,
          instructionSectionTitle: tempEarningRule.translations?.en?.instructionSectionTitle,
          instructionSectionContent:
            tempEarningRule.translations?.en?.instructionSectionContent,
          detailSectionTitle: tempEarningRule.translations?.en?.detailSectionTitle,
          detailSectionContent: tempEarningRule.translations?.en?.detailSectionContent,
          translations: postTranslations(tempEarningRule.translations)
        };
        data[`${key}TypeRewardType`] = tempEarningRule.rewardType;
        let rewardData = {};
        if (tempEarningRule.rewardType === EarningRuleRewardType.points) {
          rewardData[`${key}TypePointsRewardTypePoints`] =
            tempEarningRule.quantity;
        } else if (tempEarningRule.rewardType === EarningRuleRewardType.badge) {
          rewardData[`${key}TypeBadgeRewardTypeQuantity`] =
            tempEarningRule.quantity;
          rewardData[`${key}TypeBadgeRewardTypeQuantityInviter`] =
            tempEarningRule.inviterQuantity;
          rewardData[`${key}TypeBadgeRewardTypeQuantityInvitee`] =
            tempEarningRule.inviteeQuantity;
        }else if (tempEarningRule.rewardType === EarningRuleRewardType.stamp) {
          rewardData[`${key}TypeStampRewardTypeQuantity`] =
            tempEarningRule.quantity;
          rewardData[`${key}TypeStampRewardTypeQuantityInviter`] =
            tempEarningRule.inviterQuantity;
          rewardData[`${key}TypeStampRewardTypeQuantityInvitee`] =
            tempEarningRule.inviteeQuantity;
        } else {
          rewardData[`${key}TypeCouponRewardTypeCouponTemplate`] =
            tempEarningRule.coupons.pk;
          rewardData[`${key}TypeCouponRewardTypeQuantity`] =
            tempEarningRule.quantity;
          rewardData[`${key}TypeCouponRewardTypeQuantityInviter`] =
            tempEarningRule.inviterQuantity;
          rewardData[`${key}TypeCouponRewardTypeQuantityInvitee`] =
            tempEarningRule.inviteeQuantity;
        }

        let values = {};
        switch (tempEarningRule.type) {
          case EarningRuleType.generalPurchase:
            values = {
              generalPurchaseTypeMinimumSpending: tempEarningRule.minSpending,
              generalPurchaseTypeMaximumSpending:
                tempEarningRule.maxSpending || null,
              generalPurchaseTypeEligibleNumberOfDaysSincePurchase:
                tempEarningRule.eligibleDays || null,
              // generalPurchaseTypeWillExcludeDeliveryCost:
              //   tempEarningRule.isExcludeDeliveryCost || false,
              // generalPurchaseTypeWillExcludeOtherCharges:
              //   tempEarningRule.isExcludeOtherCharges || false,
              generalPurchaseTypeLimitedToPaymentMethods: [...(tempEarningRule?.paymentMetheds ?? []).map((item) => item?.pk)],
              generalPurchaseTypeLimitedToStores: [...(tempEarningRule?.selectMallStores ?? []).map((item) => item?.pk),
                ...(tempEarningRule?.selectFreshMarketStores ?? []).map((item) => item?.pk)],
              generalPurchaseTypeRewardType: tempEarningRule.rewardType,
              // generalPurchaseTypeLimitedSkus: tempEarningRule.specialSkus,
              generalPurchaseTypeLimitedSkus: tempEarningRule.specialSkus?.map(
                (item) => ({
                  skuId: item.pk,
                  sku: item.name,
                  categoryName: item.category.name,
                  categoryId: item.category.pk,
                }),
              ),
              generalPurchaseTypeOverallLimit: tempEarningRule.overallLimit || null,
              generalPurchaseTypePerHeadLimit: tempEarningRule.perHeadLimit || null,
              generalPurchaseTypePeriodicLimit: tempEarningRule.periodicLimit || null,
              generalPurchaseTypePeriodicLimitEffectiveNumberOfDays:
                tempEarningRule.periodicLimitDays || null,
              generalPurchaseTypePerHeadPeriodicLimit:
                tempEarningRule.perHeadPeriodicLimit || null,
              generalPurchaseTypePerHeadPeriodicLimitEffectiveNumberOfDays:
                tempEarningRule.perHeadPeriodicLimitDays || null,
            };
            if (tempEarningRule.rewardType === EarningRuleRewardType.coupons) {
              values['generalPurchaseTypeCouponRewardTypeCouponTemplate'] =
                tempEarningRule.coupons.pk;
              values['generalPurchaseTypeCouponRewardTypeQuantity'] =
                tempEarningRule.quantity;
            } else if (tempEarningRule.rewardType === EarningRuleRewardType.badge) {
              values[
                'generalPurchaseTypeBadgeRewardTypeBadgesPerXDollarsSpent'
              ] = tempEarningRule.quantity;
              values['generalPurchaseTypeBadgeRewardTypeX'] =
                tempEarningRule.rewardTypeX;
            } else if (tempEarningRule.rewardType === EarningRuleRewardType.stamp) {
              values[
                'generalPurchaseTypeStampRewardTypeStampsPerXDollarsSpent'
              ] = tempEarningRule.quantity;
              values['generalPurchaseTypeStampRewardTypeX'] =
                tempEarningRule.rewardTypeX;
              values['generalPurchaseTypeStampRewardTypeBonus'] = tempEarningRule.stampRewardTypeBonus;
              if (tempEarningRule.stampRewardTypeBonus) {
                values['generalPurchaseTypeStampRewardTypeBonusType'] = tempEarningRule.bonusRewardsRule;
                values['generalPurchaseTypeStampRewardTypeBonusMultiplier'] = tempEarningRule.multiplier || 1;
                values['generalPurchaseTypeStampRewardTypeBonusAdditional'] = tempEarningRule.additional || 0;
                values['generalPurchaseTypeStampRewardTypeBonusCriteriaRelationship'] = tempEarningRule.bonusCriteriaRelationship;
                values['generalPurchaseTypeStampRewardTypeBonusPaymentMethods'] = [...(tempEarningRule.bonusPaymentMethedsLimit ? tempEarningRule?.bonusPaymentMetheds ?? [] : []).map((item) => item?.pk)];
                values['generalPurchaseTypeStampRewardTypeBonusStores'] = [...(tempEarningRule?.bonusSelectMallStores ?? []).map((item) => item?.pk),
                ...(tempEarningRule?.bonusSelectFreshMarketStores ?? []).map((item) => item?.pk)];
                values['generalPurchaseTypeStampRewardTypeBonusTransactionStartDatetime'] = tempEarningRule.bonusPeriodLimit ? tempEarningRule.bonusStartDate : null;
                values['generalPurchaseTypeStampRewardTypeBonusTransactionEndDatetime'] = tempEarningRule.bonusPeriodLimit ? tempEarningRule.bonusEndDate : null;
                values['generalPurchaseTypeStampRewardTypeBonusTargetCustomerGroups'] = [...(tempEarningRule.bonusTargetCustomerLimit ? tempEarningRule.customerGroup ?? [] : []).map((item) => item?.pk)];
                values['generalPurchaseTypeStampRewardTypeBonusTargetCustomerSegments'] = [...(tempEarningRule.bonusTargetCustomerLimit ? tempEarningRule.segments ?? [] : []).map((item) => item?.pk)];
              } else {
                values['generalPurchaseTypeStampRewardTypeBonusType'] = BonusRewardsRule.Multiplier;
                values['generalPurchaseTypeStampRewardTypeBonusMultiplier'] = 1;
                values['generalPurchaseTypeStampRewardTypeBonusAdditional'] = 0;
                values['generalPurchaseTypeStampRewardTypeBonusCriteriaRelationship'] = BonusCriteriaRelationship.or;
                values['generalPurchaseTypeStampRewardTypeBonusPaymentMethods'] = [];
                values['generalPurchaseTypeStampRewardTypeBonusStores'] = [];
                values['generalPurchaseTypeStampRewardTypeBonusTransactionStartDatetime'] = null;
                values['generalPurchaseTypeStampRewardTypeBonusTransactionEndDatetime'] = null;
                values['generalPurchaseTypeStampRewardTypeBonusTargetCustomerGroups'] = [];
                values['generalPurchaseTypeStampRewardTypeBonusTargetCustomerSegments'] = [];
              }
            } else {
              values[
                'generalPurchaseTypePointsRewardTypePointsPerXDollarsSpent'
              ] = tempEarningRule.quantity;
              values['generalPurchaseTypePointsRewardTypeX'] =
                tempEarningRule.rewardTypeX;
            }
            break;
          case EarningRuleType.gpsCheckIn:
            values = {
              gpsCheckInTypeLatitude: tempEarningRule.latitude,
              gpsCheckInTypeLongitude: tempEarningRule.longitude,
              gpsCheckInTypeRadiusInMeter: tempEarningRule.radius,
              gpsCheckInTypeOverallLimit: tempEarningRule.overallLimit || null,
              gpsCheckInTypePerHeadLimit: tempEarningRule.perHeadLimit || null,
              gpsCheckInTypePeriodicLimit: tempEarningRule.periodicLimit || null,
              gpsCheckInTypePeriodicLimitEffectiveNumberOfDays:
                tempEarningRule.periodicLimitDays || null,
              gpsCheckInTypePerHeadPeriodicLimit:
                tempEarningRule.perHeadPeriodicLimit || null,
              gpsCheckInTypePerHeadPeriodicLimitEffectiveNumberOfDays:
                tempEarningRule.perHeadPeriodicLimitDays || null,
              ...rewardData,
            };
            break;
          case EarningRuleType.memberReferral:
            values = {
              memberReferralTypeBeneficiary: "BOTH_INVITER_AND_INVITEE",
              memberReferralTypeLimit: tempEarningRule.maxRewards || null,
              ...rewardData,
            };
            break;
          case EarningRuleType.birthday:
            values = {
              birthdayTypePeriodStartDate: tempEarningRule.birthStart,
              birthdayTypePeriodEndDate: tempEarningRule.birthEnd,
              ...rewardData,
            };
            break;
          case EarningRuleType.qrCodeScanning:
            values = {
              qrCodeScanningTypeQrCodes: tempEarningRule.qrCodes,
              qrCodeScanningTypeOverallLimit: tempEarningRule.overallLimit || null,
              qrCodeScanningTypePerHeadLimit: tempEarningRule.perHeadLimit || null,
              qrCodeScanningTypePeriodicLimit: tempEarningRule.periodicLimit || null,
              qrCodeScanningTypePeriodicLimitEffectiveNumberOfDays:
                tempEarningRule.periodicLimitDays || null,
              qrCodeScanningTypePerHeadPeriodicLimit:
                tempEarningRule.perHeadPeriodicLimit || null,
              qrCodeScanningTypePerHeadPeriodicLimitEffectiveNumberOfDays:
                tempEarningRule.perHeadPeriodicLimitDays || null,
              ...rewardData,
            };
            break;
          case EarningRuleType.fillingForm:
              values = {
                fillingFormTypeForm: tempEarningRule.fillingForm.pk,
                fillingFormTypeDefinitionType: tempEarningRule.definitionType,
                fillingFormTypeOverallLimit: tempEarningRule.overallLimit || null,
                fillingFormTypePerHeadLimit: tempEarningRule.perHeadLimit || null,
                fillingFormTypePeriodicLimit: tempEarningRule.periodicLimit || null,
                fillingFormTypePeriodicLimitEffectiveNumberOfDays:
                  tempEarningRule.periodicLimitDays || null,
                fillingFormTypePerHeadPeriodicLimit:
                  tempEarningRule.perHeadPeriodicLimit || null,
                  fillingFormTypePerHeadPeriodicLimitEffectiveNumberOfDays:
                  tempEarningRule.perHeadPeriodicLimitDays || null,
                ...rewardData,
              };
              break;
          case EarningRuleType.newMember:
            values = {
              newMemberTypePromotionCodes: tempEarningRule.newMemberTypePromotionCodes,
              ...rewardData,
            };
            break;
          default:
            values = {
              ...rewardData,
            };
            break;
        }

        // let serviceArgs = [createEarningRule, { ...data, ...values }];
        // if (tempEarningRule.pk) {
        //   serviceArgs = [
        //     updateEarningRule,
        //     { id: tempEarningRule.pk, ...data, ...values },
        //   ];
        // }
        if (tempEarningRule.pk) {
          data['id'] = tempEarningRule.pk
        }
        const serviceArgs = [createOrUpdateEarningRule, { ...data, ...values }]
        console.log('@@493: ', { ...data, ...values });
        const afterAction = payload.afterAction || (() => {});
        function* onSuccess(data) {
          console.log('@@267', data);
          if (
            ('createEarningRule' in data && data.createEarningRule.errors) ||
            ('updateEarningRule' in data && data.updateEarningRule.errors)
          ) {
            yield put({
              type: 'updateState',
              payload: {
                createStatus: APIStatus.failed,
                formChanged: false,
                checked: CheckStatus.initOrNotChecked,
              },
            });
          } else {
            yield put({
              type: 'updateState',
              payload: {
                createStatus: APIStatus.success,
                formChanged: false,
                earningRule: { pk: data.createEarningRule?.node?.pk },
                checked: CheckStatus.initOrNotChecked,
              },
            });
          }

          yield put({ type: 'removeEarningRuleFromCookie' });
          afterAction();
        }

        function* onFail(errors) {
          console.log('@275:', errors);

          yield put({
            type: 'updateState',
            payload: {
              createStatus: APIStatus.failed,
              formChanged: false,
              checked: CheckStatus.initOrNotChecked,
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFail);
      },
      { type: 'takeLatest' },
    ],
  },
};
