import {
  getCampaignList,
  getCampaignCategoryList,
  deleteCampaign,
  getTransactionNode,
} from '../services/CampaignAPIHelper';
import { sessionDataKey } from './CreateCampaignModel';
import {
  convertNumberToCursor,
  getObjectFromSessionStorage,
  saveToSessionStorage,
} from '../utils';
import {
  checkCampaignIsExpired,
  getCampignListDisplayTime,
} from '../utils/TimeFormatUtil';
import { loading, apiWithResponseHandle } from './LoadingUtil';
import { ApprovalStatus, CampaignDisplayIn, EarningRuleTypeKey, LanguageConfig, MessageChannel, MissionCampaignMode, PublishTagType } from '../config/CustomEnums';
import { CampaignType, SESSION_KEYS } from '../config/CustomEnums';
import { createModel } from './BaseModel';
import { getCampaignDisplayInTitle } from '../components/campaign/campaignCreation/CreateCampaignStepOneConfigs';

export const FIRST_TIME_ENTER_CAMPAIGN = {
  resume: 'FIRST_TIME_ENTER_CAMPAIGN',
  eStampResume: 'FIRST_TIME_ENTER_ESTAMP_CAMPAIGN',
  eMissionResume: 'FIRST_TIME_ENTER_EMISSION_CAMPAIGN',
};

const SHOW_RESUME_POP = {
  resume: 'SHOW_RESUME_POP',
  eStampResume: 'SHOW_ESTAMP_RESUME_POP',
  eMissionResume: 'SHOW_EMISSION_RESUME_POP',
};

export const getLoadResumeType = (displayIn) => {
  let loadType = sessionDataKey.objectKey
  let resumeType = 'resume'
  if (displayIn === CampaignDisplayIn.estamp) {
    loadType = sessionDataKey.estampObjectKey
    resumeType = 'eStampResume'
  } else if (displayIn === CampaignDisplayIn.emission) {
    loadType = sessionDataKey.emissionObjectKey
    resumeType = 'eMissionResume'
  }
  console.log("@44", loadType, resumeType)
  return [loadType, resumeType]
}

const getCampaignMessageChannel = (channels) => {
  if (!channels || channels.length === 0) {
    return '-';
  }
  const newChannels = channels.map((channel) => {
    const channelLowercase = channel.toLowerCase();
    if (channelLowercase.includes('email')) {
      return MessageChannel.email;
    }
    if (channelLowercase.includes('push')) {
      return MessageChannel.push;
    }
    if (channelLowercase.includes('sms')) {
      return MessageChannel.sms;
    }
    if (channelLowercase.includes('web')) {
      return MessageChannel.web;
    }
    return channel;
  });
  const channelString = newChannels.toString();
  return channelString || '-';
};

const getFormatedStatus = (campaign) => {
  if (campaign.approvalStatus !== ApprovalStatus.published) {
    switch (campaign.approvalStatus) {
      case ApprovalStatus.draft:
        return PublishTagType.draft
      case ApprovalStatus.pendingForApproval:
        return PublishTagType.pendingForApproval
      case ApprovalStatus.pendingForPublish:
        return PublishTagType.pendingForPublish
      default:
        return PublishTagType.draft
    } 
  }
  const isExpired = checkCampaignIsExpired(campaign.endDate);
  if (!isExpired) {
    return PublishTagType.published;
  }
  return PublishTagType.expired;
};

const getInitialState = () => ({
  listFields: [
    { displayName: 'ID', fieldName: 'displayId' },
    { displayName: 'Name', fieldName: 'name', orderField: 'name' },
    { displayName: 'Display Section', fieldName: 'displayInName' },
    { displayName: 'Message Channel', fieldName: 'displayMessageChannels' },
    { displayName: 'Brand', fieldName: 'displayBrand', orderField: 'brand' },
    {
      displayName: 'Target customers',
      fieldName: 'numberOfCustomersVisible',
      orderField: 'targetCustomers',
    },
    {
      displayName: 'Active Period',
      fieldName: 'displayActivePeriod',
      orderField: 'startDate',
    },
    {
      displayName: 'Visible Period',
      fieldName: 'displayVisiblePeriod',
      orderField: 'displayStartDate',
    },
    {
      displayName: 'No. of Likes',
      fieldName: 'numberOfLikes',
      orderField: 'numberOfLikes',
    },
    {
      displayName: 'No. of Bookmarks',
      fieldName: 'numberOfBookmarks',
      orderField: 'numberOfBookmarks',
    },
    { displayName: 'Status', fieldName: 'displayStatus' },
  ],
  campaignList: [],
  totalCount: 0,
  maxDisplayPriority: 0,
  totalPage: 0,
  pageInfo: {
    startCursor: 0,
    endCursor: 0,
  },
  checkedList: [],
  categories: [],
  segments: [],
  avaliableCampaignNamesList: [],
  allCampaignList: [],
  resume: {
    showResumeButton: false,
    showResumePop: false,
  },
  stampCampaignList: [],
  missionCampaignList: [],
  otherCampaignList: [],
  carParkCampaignList: [],
});

export const getTypeDisplay = (type) => {
  if (type === CampaignType.couponsCampaign) {
    return 'Coupon';
  }
  if (type === CampaignType.earningRulesCampaign) {
    return 'Earning Rule';
  }
  return 'General';
};

export const parseDropDownCampaignList = (list) => {
  return list.map((campaign) => {
    campaign.translations[LanguageConfig.english] = {
      name: campaign.name,
      language: LanguageConfig.english,
    }
    return {
      ...campaign,
      name: campaign?.name ? `[ID:${campaign?.pk}] ${campaign?.name}` : '',
    }
  })
}

export const parseTransactionMissionCampaignList = (list) => {
  const result = [];
  if (list) {
    list.forEach((campaign) => {
      const missionCampaignRelations = campaign.missionCampaignRelation?.edges || [];
      missionCampaignRelations.forEach((relationCampaign) => {
        const node = relationCampaign?.node;
        result.push({
          ...campaign,
          missionDetailCampaignPK: campaign.pk,
          missionCampaignPK: node.missionCampaign?.pk,
          missionCampaignMode: node.missionCampaign?.missionCampaignMode,
        })
      })
    })
  }
  return result;
}

const getHiddenPushlish = (displayIn) => {
  switch (displayIn) {
    case CampaignDisplayIn.eCoupon:
    case CampaignDisplayIn.emission:
    case CampaignDisplayIn.estamp:
    case CampaignDisplayIn.freshMarketCommunity:
    case CampaignDisplayIn.interestClub:
    case CampaignDisplayIn.notListing:
      return true
    default:
      return false
  }
}

const parseCampaignList = (list) => {
  return list.map((campaign) => {
    const node = campaign.node;
    const translationsEdges = node.translations?.edges;
    let translations = {};
    if (translationsEdges?.length > 0) {
      translationsEdges.forEach((item) => {
        const node = item.node;
        translations[node.language] = {
          language: node.language,
          name: node.name,
        }
      });
    }

    return {
      id: node.id,
      pk: node.pk,
      displayId: node.displayId,
      name: node.name,
      displayEndDate: node.displayEndDate,
      displayStartDate: node.displayStartDate,
      endDate: node.endDate,
      startDate: node.startDate,
      status: node.status,
      type: node.type,
      typeDisplay: getTypeDisplay(node.type),
      displayBrand: node.brand?.edges?.map((item) => item.node.name).join(', '),
      brand: node.brand?.name,
      numberOfCustomersVisible: node.numberOfCustomersVisible,
      isExclusive: node.isExclusive,
      lastModifiedDate: node.lastModifiedDate,
      publicationDate: node.publicationDate,
      messageChannels: node.messageChannels,
      translations,
      displayMessageChannels: getCampaignMessageChannel(node.messageChannels),
      displayActivePeriod: getCampignListDisplayTime(
        node.startDate,
        node.endDate,
      ),
      order: node.displayPriority,
      coverPhoto: node.coverPhoto,
      displayVisiblePeriod: getCampignListDisplayTime(
        node.displayStartDate,
        node.displayEndDate,
      ),
      hasApprovalFlow: getHiddenPushlish(node.displayIn),
      isPublished: node.approvalStatus === ApprovalStatus.published ? true : false,
      displayStatus: getFormatedStatus(node),
      displayInName: getCampaignDisplayInTitle(node.displayIn),
      displayIn: node.displayIn,
      missionCampaignRelation: node.missionCampaignRelation,
      couponCampaignTypeCouponTemplate: node.couponCampaignTypeCouponTemplate,
      earningCampaignTypeEarningRule: node.earningCampaignTypeEarningRule,
      rewardCoupon: node.earningCampaignTypeEarningRule?.[`${EarningRuleTypeKey[node?.earningCampaignTypeEarningRule?.type]}TypeCouponRewardTypeCouponTemplate`],
      campaignTransactionError: node.campaignTransactionError ? JSON.parse(node.campaignTransactionError)?.map((item) => {
        return {
          receiptTitle: item?.[0],
          errorKey: item?.[1],
        }
      }) : [],
      numberOfLikes: node.numberOfLikes,
      numberOfBookmarks: node.numberOfBookmarks,
    };
  });
};

export default createModel({
  namespace: 'campaignList',
  params: {
    parse: (data) => parseCampaignList(data.campaigns.edges),
    dataKey: SESSION_KEYS.CAMPAIGN_LIST_SESSION_KEY,
    deleteAPI: deleteCampaign,
    listAPI: getCampaignList,
    parseDetail: (data) => {},
    objectKey: 'campaigns',
  },
  states: getInitialState(),
  reducers: {
    updateAvaliableCampaignNamesList(state, { payload }) {
      const campaignList = payload.list.map((campaign) => {return {...campaign.node}});
      return {
        ...state,
        avaliableCampaignNamesList: campaignList.map((item) => `[ID:${item.pk}] ${item.name}`),
      };
    },
    updateCampaignListForTransaction(state, { payload }) {
      const page = payload.page || 1;
      return {
        ...state,
        [payload.stateKey]: page > 1 ? [...state[payload.stateKey], ...payload[payload.stateKey]] : payload[payload.stateKey],
      }
    }
  },

  effects: {
    *campaignResume({ payload }, { select, put }) {
      const [loadType, resumeType] = getLoadResumeType(payload?.displayIn)
      const campaign = getObjectFromSessionStorage(loadType);
      if (!campaign?.campaignType) {
        yield put({
          type: 'updateState',
          payload: {
            [resumeType]: { showResumeButton: false, showResumePop: false },
          },
        });
        saveToSessionStorage(SHOW_RESUME_POP[resumeType], null);
        return;
      }
      const showResumePop = getObjectFromSessionStorage(SHOW_RESUME_POP[resumeType]);
      const firstTimeEnterCampaign = getObjectFromSessionStorage(
        FIRST_TIME_ENTER_CAMPAIGN[resumeType],
      );
      if (!firstTimeEnterCampaign) {
        saveToSessionStorage(FIRST_TIME_ENTER_CAMPAIGN[resumeType], true);
      }
      yield put({
        type: 'updateState',
        payload: {
          [resumeType]: {
            showResumeButton: true,
            showResumePop:
              showResumePop !== false && firstTimeEnterCampaign === false,
          },
        },
      });
    },

    *resumeAction({ payload }, { select, put }) {
      const [loadType, resumeType] = getLoadResumeType(payload?.displayIn)
      const hideResumePop = payload.hideResumePop;
      saveToSessionStorage(SHOW_RESUME_POP[resumeType], !hideResumePop);
      const showResumeButton = yield select(
        (state) => state.campaignList[resumeType].showResumeButton,
      );
      yield put({
        type: 'updateState',
        payload: {
          [resumeType]: {
            showResumeButton: showResumeButton,
            showResumePop: false,
          },
        },
      });
    },

    getCampaignList: [
      function* ({ payload }, { put }) {
        const page = payload.page || 1;
        const reverse = payload.reverse;
        const type = payload.type || '';
        const searchKey = payload.searchKey;
        const pageCursor = page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const ssoUid = payload.ssoUid || '';
        console.log('@@251: ', type);
        const serviceArgs = [
          getCampaignList,
          pageCursor,
          {
            type: type === CampaignType.allTypes ? '' : type,
            reverse,
            searchKey: searchKey || '',
            ssoUid,
            isAvailable: payload.isSelectorLoad,
            isPublished: payload.isPublished,
            isSimpleList: payload.isSimpleList,
            others: payload,
          }
        ];

        function* onSuccess(data) {
          const campaigns = data.campaigns;
          yield put({
            type: 'updateListData',
            payload: {
              ...payload,
              data: parseCampaignList(campaigns.edges),
              totalCount: campaigns.totalCount,
              pageInfo: campaigns.pageInfo,
              extraData: {
                maxDisplayPriority: campaigns.maxDisplayPriority,
              }
            },
          });
          // if (payload.isSelectorLoad) {
          //   yield put({
          //     type: 'assembleAllCampaignList',
          //     payload: {
          //       list: campaigns.edges,
          //       page,
          //     },
          //   });
          // } else {
          //   yield put({
          //     type: 'assembleCampaignList',
          //     payload: {
          //       list: campaigns.edges,
          //       totalCount: campaigns.totalCount,
          //       pageInfo: campaigns.pageInfo,
          //     },
          //   });
          // }
        }
        yield payload.disabledLoading ? apiWithResponseHandle(serviceArgs, onSuccess) : loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    getMallCampaignListForTransaction: [
      function* ({ payload }, { put }) {
        const page = payload.page || 1;
        const searchKey = payload.searchKey;
        const afterAction = payload.afterAction || (() => {});
        const pageCursor = page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';

        const serviceArgs = [
          getCampaignList,
          pageCursor,
          {
            searchKey: searchKey || '',
            isAvailable: payload.isSelectorLoad,
            isPublished: payload.isSelectorLoad,
            others: payload,
          }
        ];

        function* onSuccess(data) {
          const campaigns = data.campaigns;
          let stateKey = '';
          if (payload?.isStampCampaign) {
            stateKey = 'stampCampaignList';
          }
          if (payload?.isMissionCampaign) {
            stateKey = 'missionCampaignList';
          }
          if (payload?.isOtherCampaign) {
            stateKey = 'otherCampaignList';
          }
          if (payload?.isCarParkCampaign) {
            stateKey = 'carParkCampaignList';
          }
          const campaignData = stateKey === 'missionCampaignList' ? parseTransactionMissionCampaignList(parseCampaignList(campaigns.edges)) : parseCampaignList(campaigns.edges);
          console.log("apistateKey", stateKey)
          yield put({
            type: 'updateCampaignListForTransaction',
            payload: {
              [stateKey]: campaignData,
              stateKey: stateKey,
              page,
            },
          });
          afterAction(campaignData);
        }
        yield loading(serviceArgs, onSuccess);
      },
    ],

    getCampaignListForTransaction: [
      function* ({ payload }, { put }) {
        const page = payload.page || 1;
        const searchKey = payload.searchKey;
        const afterAction = payload.afterAction || (() => {});
        const pageCursor = page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const errorFilter = payload?.transactionErrorFilter || {};
        let formatTransactionErrorFilter = `membershipId: "${errorFilter?.membershipId}"`;
        formatTransactionErrorFilter += `, campaignType: "${errorFilter?.campaignType}"`;
        formatTransactionErrorFilter += `, receipts: [${errorFilter?.receipts}]`;

        const serviceArgs = [
          getCampaignList,
          pageCursor,
          {
            searchKey: searchKey || '',
            isAvailable: payload.isSelectorLoad,
            isPublished: payload.isSelectorLoad,
            others: payload,
            fieldNode: getTransactionNode(formatTransactionErrorFilter),
          }
        ];

        function* onSuccess(data) {
          const campaigns = data.campaigns;
          let stateKey = '';
          if (payload?.isStampCampaign) {
            stateKey = 'stampCampaignList';
          }
          if (payload?.isMissionCampaign) {
            stateKey = 'missionCampaignList';
          }
          if (payload?.isOtherCampaign) {
            stateKey = 'otherCampaignList';
          }
          if (payload?.isCarParkCampaign) {
            stateKey = 'carParkCampaignList';
          }
          const campaignData = stateKey === 'missionCampaignList' ? parseTransactionMissionCampaignList(parseCampaignList(campaigns.edges)) : parseCampaignList(campaigns.edges);
          yield put({
            type: 'updateCampaignListForTransaction',
            payload: {
              [stateKey]: campaignData,
              stateKey: stateKey,
              page,
            },
          });
          afterAction(campaignData);
        }
        yield loading(serviceArgs, onSuccess);
      },
    ],

    getCamapigncategories: [
      function* ({ payload }, { put }) {
        const serviceArgs = [getCampaignCategoryList, payload];
        function* onSuccess(data) {
          yield put({
            type: 'assembleCategory',
            payload: { list: data.campaignCategories.edges },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    duplicate: [
      function* ({ payload }, { put }) {
        console.log('duplicate campaign', payload);
        yield put({
          type: 'createCampaign/duplicateCampaign',
          payload: {
            campaignId: payload.data.pk,
            afterAction: payload.afterAction,
          },
        });
      },
      { type: 'takeLatest' },
    ],

    delete: [
      function* ({ payload }, { put }) {
        yield put({ type: 'createCampaign/deleteCampaigns', payload });
      },
      { type: 'takeLatest' },
    ],

    getAvaliableCampaignNamesList: [
      function* ({ payload }, { put }) {
        const reverse = payload.reverse;
        const type = payload.type || '';
        const ssoUid = payload.ssoUid || '';
        const serviceArgs = [
          getCampaignList,
          '',
          {
            type: type === CampaignType.allTypes ? '' : type,
            reverse,
            ssoUid,
            others: payload,
            isSimpleList: true,
          }
        ];

        function* onSuccess(data) {
          const campaigns = data.campaigns;
          yield put({
            type: 'updateAvaliableCampaignNamesList',
            payload: {
              list: campaigns.edges,
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
});
