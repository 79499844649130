import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../campaign/campaignCreation/PropertiesGeneralSection.scss';
import './RewardSection.scss';
import CustomRadios from '../base/CustomRadios';
import {
  CustomTitleLabel,
  CustomNumberInputWithUnit,
} from './CustomBaseComponments';
import { useCompare } from '../../utils';
import { useFormContext } from 'react-hook-form';
import InputFieldControl from '../base/InputFieldControl';
import { ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';

function ReferralSection({

}) {
  const radioOptions = [
    { label: 'Inviter', value: 'INVITER' },
    { label: 'Invitee', value: 'INVITEE' },
    { label: 'Both', value: 'BOTH_INVITER_AND_INVITEE' },
  ];

  const { watch, formState, setValue } = useFormContext();
  const errors = formState.errors;
  const fieldError = Object.keys(errors || []).length;

  const referralType = watch('referralType') || setValue('referralType', 'BOTH_INVITER_AND_INVITEE', { shouldDirty: true })
  const maxRewards = watch('maxRewards')

  return (
    <>
      <label className="create-section-title">Reward limit</label>
      {/* <CustomTitleLabel title="Beneficiary" />
      <div style={{ display: 'flex' }}>
        <CustomRadios
          onChange={(value) => setValue('referralType', value, { shouldDirty: true })}
          default={referralType}
          options={radioOptions}
        />
      </div> */}

      {/* {referralType !== 'INVITEE' ? ( */}
        {/* <> */}
          <InputFieldControl
            name='maxRewards'
            tips={'Max. number of rewards to be issued per customer'}
            title='Per head limit (optional)'
            value={maxRewards}
            setValue={(value) => {
              setValue('maxRewards', value, { shouldDirty: true });
            }}
            type='number'
          />
        {/* </>
      ) : null} */}
      <ReactHookFormErrorMessage errors={errors} id='memberReferralLimit' />
    </>
  );
}

export default ReferralSection;
